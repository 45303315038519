import React from 'react';
import Link from 'next/link';
import { connect } from 'react-redux';
import { closeCartAction } from '../state/actions/cartActions';
import { initCheckoutEvent } from '../state/actions/analyticsActions';
import { slide as Menu } from 'react-burger-menu';
import { ExitIcon } from '../modules/Icons';
import { ApplicationState } from '../types/state/storeTypes';
import { CartWithProductsProps } from '../types/layout/ShoppingCartTypes';
import { CartState } from '../types/state/reducers/cartReducersTypes';

import CartProduct from '../components/layout/shoppingCart/CartProduct';
import BundleDiscountUi from '../components/checkout/BundleDiscountUi';
import AppliedDiscountCodeUi from '../components/checkout/AppliedDiscountCodeUi';
import BundleDiscountLoader from '../components/checkout/BundleDiscountLoader';
import { ThreeDots } from 'react-loader-spinner';

import {
  changeLicenseThunk,
  changeDiscountThunk,
  removeProductThunk,
  initialOrderThunk,
  clearCartThunk
} from '../state/actions/cartActions';
import cartHelpers from '../helpers/cart';


class ShoppingCart extends React.Component<any, {}> {
  constructor(props) {
    super(props);

    this.closeShoppingCart = this.closeShoppingCart.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.applyDiscount = this.applyDiscount.bind(this);
    this.setLicenseType = this.setLicenseType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearCart = this.clearCart.bind(this);
  }

  closeShoppingCart(): void {
    this.props.dispatch(closeCartAction());
  }

  removeItem(itemId: number): void {
    this.props.dispatch(removeProductThunk(this.props.cart.order, itemId));
  }

  clearCart(): void {
    this.props.dispatch(clearCartThunk(this.props.cart.order));
  }

  applyDiscount(code: string): void {
    this.props.dispatch(changeDiscountThunk(this.props.cart.order, code));
  }

  setLicenseType(licenseType: string): void {
    this.props.dispatch(changeLicenseThunk(this.props.cart.order, licenseType));
  }

  handleSubmit(): void {
    this.props.dispatch(closeCartAction());
    this.props.dispatch(initCheckoutEvent());
  }

  componentDidMount() {
    this.props.dispatch(initialOrderThunk());
  }

  render() {
    const { cart, dispatch } = this.props;
    let totalProducts = Object.keys(cart.order.products).length;
    const cartActions = {
      applyDiscount: this.applyDiscount,
      setLicenseType: this.setLicenseType,
      removeItem: this.removeItem,
      handleSubmit: this.handleSubmit,
      clearCart: this.clearCart
    };
    return (
      <Menu
        pageWrapId="layout-wrapper"
        outerContainer="app-wrapper"
        customBurgerIcon={false}
        right
        disableCloseOnEsc
        onClose={this.closeShoppingCart}
        isOpen={cart.active}
        className="w-full md:w-auto md:block md:max-w-[600px] md:min-w-[400px] p-6 bg-a-dark-gray overflow-y-scroll noscrollbar top-[70px]"
      >
        <div className="text-white " tabIndex={-1} data-testid="cartWrapper">
          <div className="flex items-center mb-4">
            <div className="text-[24px]  inter">Your Cart</div>
            <button onClick={this.closeShoppingCart} className="ml-auto">
              <ExitIcon />
            </button>
          </div>
          <EmptyCart cart={cart} />
          <CartWithProducts
            cart={cart}
            dispatch={dispatch}
            totalProducts={totalProducts}
            cartCreators={cartActions}
          />
        </div>
      </Menu>
    );
  }
}

const CartLoadingUi: React.FC<{ cart: CartState }> = ({ cart }) => {
  if (!cart.loading) return;
  return (
    <div className="py-4 w-full flex justify-center">
      <ThreeDots
        height="64"
        width="64"
        radius="6"
        color="#00A1E0"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </div>
  );
}

const CartWithProducts: React.FC<CartWithProductsProps> = (props) => {
  const { cart, cartCreators, totalProducts } = props;
  const { filterOutDiscountItem } = cartHelpers;

  if (totalProducts < 1) return null;

  function renderProducts(): React.ReactNode {
    return Object.values(cart.order.products).filter(filterOutDiscountItem).map((item, index) => {
      return (
        <CartProduct
          key={`cart-product-${index}`}
          product={item}
          rmItem={cartCreators.removeItem}
        />
      );
    });
  }

  return (
    <div className="pb-12">
      <div
        className="cart-row-products overflow-y-scroll noscrollbar"
        data-testid="cartProductsContainer"
      >
        {renderProducts()}
      </div>
      <CartLoadingUi cart={cart} />
      <div className="flex flex-col gap-4">
        <Link
          href="#clearcart"
          className="text-a-light-gray block rounded-[5px] px-5 bg-[rgba(255,255,255,0.05)] w-full color-white border-0 text-[16px] text-center py-2 my-4"
          onClick={cartCreators.clearCart}
          data-testid="cartSubmitBtn"
        >
          Clear Cart
        </Link>

        <div className="flex flex-col gap-2.5">
          <BundleDiscountUi />
          <AppliedDiscountCodeUi />
        </div>

        <div className="flex py-2 font-medium text-[20px]">
          <p>Total</p>
          <p className="ml-auto" data-testid="orderTotal">
            ${cart.order.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </p>
        </div>
      </div>

      <Link
        href="/checkout"
        className="mt-6 rounded-[5px] block bg-[#00B74F] color-white border-0 w-full uppercase text-[20px] text-center font-[Eurostile] pt-3 pb-4 font-semibold"
        onClick={cartCreators.handleSubmit}
        data-testid="cartSubmitBtn"
      >
        Checkout
      </Link>

      <div className="border-t border-gray-800 pt-8 mt-8"></div>

      <BundleDiscountLoader />
    </div>
  );
};

const EmptyCart: React.FC<{ cart: CartState }> = ({ cart }) => {
  if (Object.keys(cart.order.products).length > 0) return null;
  return (
    <div className="text-a-light-gray py-8" data-testid="emptyCartContainer">
      <CartLoadingUi cart={cart} />
      <AppliedDiscountCodeUi />
      <p>Cart is Empty</p>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    cart: state.cart
  };
};

export default connect(mapStateToProps)(ShoppingCart);
