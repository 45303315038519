import React from 'react';
import { BaseContent, Question, AgreePrompt, ConfirmLicense } from './LicenseConfirmationModal';
import LicenseForm from './LicenseForm';
import LicenseError from './LicenseError';
import { FlowContentProps } from '../../types/components/license-profile/FlowContent';

import {
  STEP_TYPE_BASE,
  STEP_TYPE_QUESTION,
  STEP_TYPE_AGREE,
  STEP_TYPE_FORM,
  STEP_TYPE_CONFIRM,
} from './LicenseConfigurations';

export const FlowContent: React.FC<FlowContentProps> = ({
  flowState,
  showLicenseError,
  handleNext,
  handleBack,
  handleChange,
  user,
  subscription,
  currentStep,
  isUpdateProfile,
  confirmLicenseContent,
  setConfirmLicenseContent,
  onClose,
  isUpdate
}) => {

  if (showLicenseError) {
    return (
      <LicenseError
        onNext={handleNext}
        onBack={handleBack}
        onClose={onClose}
        currentLicense={{
          title: flowState.currentLicenseType || 'Unknown',
          descriptionList:
            flowState.licenseErrorCards[flowState.currentLicenseType]?.descriptionList || []
        }}
        suggestedLicense={{
          title: flowState.suggestedLicenseType || 'Unknown',
          descriptionList:
            flowState.licenseErrorCards[flowState.suggestedLicenseType]?.descriptionList || []
        }}
      />
    );
  }

  const currentConfig = flowState.flowConfig[currentStep];
  if (!currentConfig) {
    return null;
  }

  const commonProps = {
    onNext: handleNext,
    onBack: handleBack,
    onChange: handleChange,
    formData: flowState.formData,
    stepIndex: currentStep,
    user,
    subscription,
    suggestedLicenseType: flowState.suggestedLicenseType,
    flowType: flowState.currentLicenseType,
    flowState,
    isUpdateProfile,
    confirmLicenseContent,
    setConfirmLicenseContent,
    isUpdate
  };

  switch (currentConfig.type) {
    case STEP_TYPE_BASE:
      return <BaseContent {...commonProps} {...currentConfig.props} />;
    case STEP_TYPE_QUESTION:
      return <Question {...commonProps} {...currentConfig.props} />;
    case STEP_TYPE_AGREE:
      const licenseData =
        currentConfig.props[flowState.suggestedLicenseType as keyof typeof currentConfig.props];
      return (
        <AgreePrompt
          {...commonProps}
          suggestedLicenseType={flowState.suggestedLicenseType}
          licenseDescription={licenseData?.licenseDescription || ''}
          disqualificationList={licenseData?.disqualificationList || []}
        />
      );
    case STEP_TYPE_FORM:
      return <LicenseForm {...commonProps} {...currentConfig.props} />;
    case STEP_TYPE_CONFIRM:
      return <ConfirmLicense {...commonProps} {...currentConfig.props} onClose={onClose} />;
    default:
      return null;
  }
};
