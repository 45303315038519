import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import { AUTH_ACTIONS } from '../../constants/actions';

import { SessionState } from '../../types/state/reducers/authReducersTypes';

import { addNotification } from '../../state/actions/notificationsActions';
import WelcomeNotification from '../shared/WelcomeNotification';
import EmailNotVerifiedNotification from '../shared/EmailNotVerifiedNotification';

const { AUTH_USER_INFO_OK } = AUTH_ACTIONS;

interface AfterLoginProps {
  afterLoginTasksCompleted: Function;
  process: 'signup' | 'login';
  postponePageReload?: boolean;
}

const routesToRefresh: Array<RegExp> = [
  new RegExp(/.*\/collections\/.*/i),
  new RegExp(/.*\/3d.*/i),
  new RegExp(/.*\/products/),
  new RegExp(/.*\/checkout/),
  new RegExp(/.*\/pricing/),
];

const AfterLogin: React.FC<any> = (props: AfterLoginProps) => {
  const { afterLoginTasksCompleted, process, postponePageReload = false } = props;
  const dispatch = useDispatch();
  const router = useRouter();

  const afterLoginHandler = async () => {
    // TEMP refresh if logged in while on some of these locations to rehydrate
    // ownership, card information, etc.
    const shouldReload = routesToRefresh.some((route: RegExp) => !!route.test(router.asPath)) && !postponePageReload;
    if (shouldReload) return window.location.reload();

    const session: SessionState = await getSession();
    dispatch({ type: AUTH_USER_INFO_OK, payload: { authenticated: true, ...session } });
    dispatch(
      addNotification({
        body: (
          <WelcomeNotification
            userFirstName={session.user.first_name}
            isFirstTimeLogin={process === 'signup'}
          />
        ),
        customUi: true
      })
    );
    if (!session.user.confirmed_at)
      dispatch(
        addNotification({
          body: <EmailNotVerifiedNotification />
        })
      );
    afterLoginTasksCompleted();
  };

  useEffect(() => {
    afterLoginHandler();
  }, []);

  return <></>;
};

export default AfterLogin;
