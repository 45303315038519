import { SubscriptionDetail, SubscriptionInterval, PlanVariantResponse } from '../types/api/SubscriptionTypes';
import { LicenseTypeDetail, LicenseTypeId, LicenseId } from '../types/api/LicenseTypesTypes';
import { User } from '../types/api/UsersTypes';

import subscriptionHelpers from './subscriptionHelpers';

const filterPlanVariants = (
  variants: Array<PlanVariantResponse>,
  interval: SubscriptionInterval,
): Array<PlanVariantResponse> => {
  return variants
        .filter(
          variant => variant.interval === interval
        ).sort(
          (a, b) => a.recurring_credits - b.recurring_credits
        );
}

const isUpgradeOrDowngrade = (
  selectedVariant: PlanVariantResponse,
  selectedLicense: LicenseTypeDetail | { id: number },
  currentPlan: SubscriptionDetail,
): 'upgrade' | 'downgrade' | false => {
  const { isLegacy } = subscriptionHelpers;
  if (isLegacy(currentPlan)) return 'upgrade';
  const currentVariant = currentPlan.plan_variant;
  const selectedVariantLicenseId = selectedLicense.id;
  if (currentPlan.license.id < selectedVariantLicenseId) return 'upgrade';
  if (currentPlan.license.id > selectedVariantLicenseId) return 'downgrade';

  // regardless of the chosen interval, if the license remains the same, changing from Plus to Essential plan is
  // considered a downgrade
  if (currentVariant.recurring_credits > 0 && selectedVariant.recurring_credits === 0) return 'downgrade';

  if (currentVariant.interval === 'month' && selectedVariant.interval === 'year') return 'upgrade';
  if (currentVariant.interval === 'year' && selectedVariant.interval === 'month') return 'downgrade';

  if (currentVariant.recurring_credits < selectedVariant.recurring_credits) return 'upgrade';
  if (currentVariant.recurring_credits > selectedVariant.recurring_credits) return 'downgrade';

  return false;
}

const getDowngradeTooltipText = (
  selectedVariant: PlanVariantResponse,
  currentPlan: SubscriptionDetail,
  selectedInterval: SubscriptionInterval,
): string => {
  const { recurring_credits, price } = selectedVariant;
  const { next_payment_date } = currentPlan;
  const intervalShorthand = selectedInterval === 'month' ? 'mo' : 'yr';
  const dateFormatted = new Date(next_payment_date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const planDescriptionText =
    selectedVariant.recurring_credits > 0
    ? `${recurring_credits} credits/${intervalShorthand}`
    : `${selectedInterval === 'month' ? 'monthly' : 'annual'} Essentials plan`;
  return `You will be downgraded to the ${planDescriptionText} ($${parseFloat(price).toFixed(2)}/${intervalShorthand}) at the end of your billing period on ${dateFormatted}.`;
}

// TODO test
const getMinimalLicenseIdForUpgrade = (
  user?: User,
  subscription?: SubscriptionDetail,
  licenseTypeOrLicense: 'license_type_id' | 'license_id' = 'license_type_id',
): LicenseTypeId => {
  if (!user) return 1;
  let minLicenseIdFromUserLicenseId: LicenseTypeId = (user.min_license_id || 1) as LicenseTypeId;
  let minLicenseIdFromUserTeam: LicenseTypeId = 1;
  let minLicenseIdFromSubscription: LicenseTypeId;

  if (user.team?.licensee_profile) {
    const { completed, invalidated } = user.team?.licensee_profile;
    if (completed && !invalidated) {
      minLicenseIdFromUserTeam =
        licenseTypeOrLicense === 'license_type_id' ?
        user.team?.licensee_profile?.license_type.id as LicenseTypeId :
        user.team?.licensee_profile?.license_id as LicenseTypeId;
    }
  }

  const licenseIdsToFactorIn = [
    minLicenseIdFromUserLicenseId,
    minLicenseIdFromUserTeam,
  ];

  const returnValue = (values: Array<LicenseTypeId>): LicenseTypeId => {
    let max = Math.max(...values);
    if (max > 3 && licenseTypeOrLicense === 'license_type_id') max = 3;
    return max as LicenseTypeId;
  }

  if (!subscription) return returnValue(licenseIdsToFactorIn);

  minLicenseIdFromSubscription =
    licenseTypeOrLicense === 'license_type_id' ?
    subscription.license.type.id as LicenseTypeId :
    subscription.license.id as LicenseTypeId;
  return returnValue([ ...licenseIdsToFactorIn, minLicenseIdFromSubscription ]);
}

const getIntervalToPreselect = (
  currentPlan: SubscriptionDetail | undefined,
  planVariants: Array<PlanVariantResponse>,
): SubscriptionInterval => {
  if (!currentPlan || !planVariants?.length) return 'month';
  const currentIntervalVariants = planVariants.filter(v => v.interval === currentPlan.plan_variant.interval);
  // if current plan is monthly and has highest credits of all monthly plans, shift interval up to year
  if (
    Math.max(...currentIntervalVariants.map(v => v.recurring_credits)) === currentPlan.plan_variant.recurring_credits
  ) return 'year';
  return currentPlan.plan_variant.interval;
}

const getPlanVariantsAvailableForSelection = (
  currentPlan: SubscriptionDetail | undefined,
  planVariants: Array<PlanVariantResponse>,
  licenseId: LicenseId,
): Array<PlanVariantResponse> => {
  return planVariants.filter(
    variant => {
      if (licenseId > currentPlan?.license.id) return true;
      return variant.id !== currentPlan.plan_variant.id &&
        variant.recurring_credits > currentPlan.plan_variant.recurring_credits
    }
  );
}

const getLicenseToPreselect = (
  licenseTypes: Array<LicenseTypeDetail>,
  currentPlan: SubscriptionDetail | undefined,
  autoSelectedPlanVariant?: PlanVariantResponse,
): LicenseTypeDetail => {
  return licenseTypes.find(
    lt => {
      if (!!autoSelectedPlanVariant) return lt.name === autoSelectedPlanVariant.plan.name;
      return lt.id === currentPlan?.license.type.id;
    }
  ) || licenseTypes[0];
}

const subscriptionUpgradeHelpers = {
  filterPlanVariants,
  isUpgradeOrDowngrade,
  getDowngradeTooltipText,
  getMinimalLicenseIdForUpgrade,
  getIntervalToPreselect,
  getPlanVariantsAvailableForSelection,
  getLicenseToPreselect,
};

export default subscriptionUpgradeHelpers;
