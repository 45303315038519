type SingleOwnership = {
  has_legitimate_license: boolean;
  has_ownership?: boolean;
};

type OwnershipMap = {
  [key: string | number]: SingleOwnership;
};

export const normalizeLicenseType = (mode: string): string => {
  const lowerType = mode.toLowerCase();
  if (lowerType === 'studio') return 'Enterprise';
  return mode.charAt(0).toUpperCase() + lowerType.slice(1);
};

export const checkLegitimateOwnership = (
  ownership: SingleOwnership | OwnershipMap | null | undefined,
  options: {
    requireOwnership?: boolean;
    additionalChecks?: (ownership: SingleOwnership) => boolean;
  } = {}
): boolean => {
  if (!ownership) return false;

  if ('has_legitimate_license' in ownership) {
    const basicCheck = !ownership.has_legitimate_license;
    const ownershipCheck = options.requireOwnership ? ownership.has_ownership : true;
    const extraChecks = options.additionalChecks?.(ownership as SingleOwnership) ?? true;
    return basicCheck && ownershipCheck && extraChecks;
  }

  return Object.values(ownership).some(o => {
    const basicCheck = !o.has_legitimate_license;
    const ownershipCheck = options.requireOwnership ? o.has_ownership : true;
    const extraChecks = options.additionalChecks?.(o) ?? true;
    return basicCheck && ownershipCheck && extraChecks;
  });
};

export const checkLicenseeProfile = (
  ownership: SingleOwnership | OwnershipMap | null | undefined,
): boolean => {
  if (!ownership) return false;

  if ('licensee_profile' in ownership) {
    return !!ownership.licensee_profile
  }

  return Object.values(ownership).some(o => 
    !!o.licensee_profile
  );
};