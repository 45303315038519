import { BundleDiscountLoaderProps, BundleDiscountLoaderStateProps } from '../../types/components/checkout/BundleDiscountLoader';
import { ApplicationState } from '../../types/state/storeTypes';

import { connect } from 'react-redux';

import { ENV } from '../../constants/environments';
import { CART_CONSTANTS } from '../../constants/ui';
const {
  BUNDLE_DISCOUNT_MIN_ITEMS_COUNT,
  BUNDLE_DISCOUNT_PERCENT,
} = CART_CONSTANTS;

const BundleDiscountLoader: React.FC<BundleDiscountLoaderProps> = ({
  cart,
}) => {
  if (ENV.displayBundleDiscountUi !== 'true') return;

  function totalNonfreeProducts(): number {
    return Object.values(cart.order.products).filter((item) => parseFloat(item.price) > 0).length;
  }

  function promoPercentage(): number {
    if (totalNonfreeProducts() > BUNDLE_DISCOUNT_MIN_ITEMS_COUNT) return 100;
    return Math.round((totalNonfreeProducts() / BUNDLE_DISCOUNT_MIN_ITEMS_COUNT) * 100);
  }

  return (
    <>
    {totalNonfreeProducts() < BUNDLE_DISCOUNT_MIN_ITEMS_COUNT && (
      <div className="bg-gray-1000 text-center p-6" cy-test-id="ui-before-discount">
        <h5 className="uppercase eurostile font-bold text-[17px]">Get An Extra {BUNDLE_DISCOUNT_PERCENT}% Off!</h5>
        <p className="text-gray-600 text-[14px]" cy-test-id="add-products-count">
          Add <span className="font-bold text-white">{BUNDLE_DISCOUNT_MIN_ITEMS_COUNT - totalNonfreeProducts()}</span> more
          products to your cart
        </p>
        <div className="relative pt-4">
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-white">
            <div
              cy-test-id="loader-bar"
              style={{ width: promoPercentage().toString() + '%' }}
              className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-a-green`}
            ></div>
          </div>
        </div>
      </div>
    )}

    {totalNonfreeProducts() >= BUNDLE_DISCOUNT_MIN_ITEMS_COUNT && (
      <div className="bg-gray-1000 text-center p-6" cy-test-id="ui-after-discount">
        <h5 className="uppercase eurostile font-bold text-[17px]">Get An Extra {BUNDLE_DISCOUNT_PERCENT}% Off!</h5>
        <p className="text-gray-600 text-[14px]">Success! You claimed this discount!</p>
        <div className="relative pt-4">
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-white">
            <div
              className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-a-green w-[100%]`}
            ></div>
          </div>
        </div>
      </div>
    )}
    </>
  )
};

const mapStateToProps = (state: ApplicationState): BundleDiscountLoaderStateProps => ({
  cart: state.cart,
});

export default connect(mapStateToProps)(BundleDiscountLoader);
