import { PlanVariantSelectionProps, PlanVariantSelectionStateProps } from '../../types/components/subscription-upgrade-modal/PlanVariantSelection';
import { SubscriptionDetail, PlanVariantResponse } from '../../types/api/SubscriptionTypes';
import { ApplicationState } from '../../types/state/storeTypes';
import { EssentialsCategoryIconThin } from '../../modules/Icons';

import { connect } from 'react-redux';
import { useEffect } from 'react';

import PlanPriceTag from '../shared/PlanPriceTag';

export const activePlanVariantButtonClass = 'bg-a-blue text-white border-a-blue';
export const inactivePlanVariantButtonClass = 'bg-transparent text-gray-600 border-gray-600';
export const disabledPlanVariantButtonClass = 'bg-gray-800 text-gray-600 border-gray-800 cursor-default';

export const activePlanVariantTierButtonClass = 'text-white bg-a-blue border-a-blue';
export const inactivePlanVariantTierButtonClass = 'text-a-gray border-[#7F898D] bg-opacity-0 hover:border-a-blue hover:bg-a-blue hover:bg-opacity-30'
export const planVariantTierButtonBasicClass = 'rounded-[4px] border-[1px] border-solid px-2 py-1.5 flex gap-2 items-center';
export const disabledPlanVariantTierButtonClass = 'bg-gray-800 text-gray-600 border-gray-800 cursor-default';

const PlanVariantSelection: React.FC<PlanVariantSelectionProps> = ({
  filteredPlanVariants,
  selectedInterval,
  currentPlan,
  selectedPlanVariant,
  onSelectedPlanVariant,
}) => {
  const autoSelectPlanVariant = () => {
    const currentPlanVisibleToUser = !!filteredPlanVariants.find(pv => isCurrentPlan(pv));
    if (!currentPlanVisibleToUser) {
      // this code block is executed when the user views plan variants that have the
      // opposite interval than the user's current plan variant
      if (currentPlan.plan_variant.interval === 'month') // user is on monthly plan
        // we either select the first non-Essentials plan variant if the user is on a non-Essentials plan
        // (because moving from non-Essentials plan to Essentials is a downgrade in any case),
        // or select the first plan if the user is on a monthly Essentials plan
        selectVariant(
          currentPlan.plan_variant.recurring_credits === 0 ?
          filteredPlanVariants[0] :
          filteredPlanVariants.filter(pv => pv.recurring_credits > 0)[0]
        );
      else // user is on annual plan
        // if customer is on annual plan and looks at monthly plans,
        // then it is a downgrade in any case, so just select the first plan
        selectVariant(filteredPlanVariants[0]);
      return;
    }
    const upgradeVariants = filteredPlanVariants.filter(pv => pv.recurring_credits > currentPlan.plan_variant.recurring_credits);
    if (upgradeVariants.length)
      selectVariant(upgradeVariants[0]);
  };

  useEffect(() => {
    if (!filteredPlanVariants) return;
    autoSelectPlanVariant();
  }, [selectedInterval]);

  const isCurrentPlan = (plan: PlanVariantResponse): boolean => {
    if (!currentPlan || !plan) return false;
    return plan.group_id === currentPlan.plan_variant.group_id;
  }

  const isSelected = (planVariant: PlanVariantResponse): boolean => selectedPlanVariant?.id === planVariant.id;

  const getTitle = (): string => selectedPlanVariant?.plan.name || '';

  const getSubtitle = (): string => {
    switch (getTitle()) {
      case 'Individual': return 'For individual artists earning under $200K/year in revenue';
      case 'Startup': return 'For small studios and teams earning under $3M/year in revenue';
      default: return '';
    }
  }

  const getStrokeColorForEssetnialIcon = (planVariant: PlanVariantResponse): string => {
    if (isCurrentPlan(planVariant)) return '#7F898D';
    if (isSelected(planVariant)) return '#FFF';
    return '#7F898D';
  }

  const renderTitleBlock = (): React.ReactElement => {
    return (
      <div>
        <h3 cy-test-id="title" className="font-bold text-white inter text-28 leading-40">
          {getTitle()}
        </h3>
        <h5 cy-test-id="subtitle" className="mt-2 font-normal text-a-gray inter text-16 leading-24">
          {getSubtitle()}
        </h5>
      </div>
    );
  }

  const selectVariant = (variant: PlanVariantResponse) => {
    if (isCurrentPlan(variant)) return;
    onSelectedPlanVariant(variant);
  }

  const renderVariantSelectionButton = (variant: PlanVariantResponse): React.ReactElement => {
    return (
      <button
        key={variant.id}
        cy-test-id={`variant-selection-button-${variant.group_id}`}
        className={`
          py-1.5 px-5 rounded-[33px] h-10 inter text-16 leading-24 font-medium border-[1px]
          ${isCurrentPlan(variant) && disabledPlanVariantButtonClass}
          ${variant.id === selectedPlanVariant?.id && activePlanVariantButtonClass}
          ${!isCurrentPlan(variant) && variant.id !== selectedPlanVariant?.id && inactivePlanVariantButtonClass}
        `}
        disabled={isCurrentPlan(variant)}
        onClick={() => selectVariant(variant)}
      >
      {
        variant.recurring_credits > 0 ?
        variant.recurring_credits :
        <EssentialsCategoryIconThin
          fill={getStrokeColorForEssetnialIcon(variant)}
        />
      }
      </button>
    );
  }

  return (
    <div cy-test-id="plan-variant-selection" className="w-full flex flex-col gap-6 p-6 bg-a-dark-gray rounded-[5px]">
      {renderTitleBlock()}
      <div cy-test-id="plan-variants">
        <h5 cy-test-id="credits-selection-label" className="font-semibold text-white inter text-16 leading-24">
          Credits per {selectedInterval}
        </h5>
        <div className="flex flex-row w-full gap-2 mt-2">
          {filteredPlanVariants.map((variant, index) => renderVariantSelectionButton(variant))}
        </div>
      </div>
      <div className="w-full mt-6">
        {selectedPlanVariant && (
          <div cy-test-id="price-holder">
            <PlanPriceTag plan={selectedPlanVariant} alignment="vertical"/>
          </div>
        )}
      </div>
    </div>
  )
};

const mapStateToProps = (state: ApplicationState): PlanVariantSelectionStateProps => ({
  currentPlan: state.auth.subscription as SubscriptionDetail,
});

export default connect(mapStateToProps)(PlanVariantSelection);
