import { ENV } from '../constants/environments';
import { BROWSER_FINGERPRINT_COOKIE_NAME } from '../constants/utils';
import { EVENT_NAMES, ATTRIBUTES } from '../constants/algolia';

import { insightsClient, EventsItems } from '@algolia/client-insights';
import { touchCookie } from '../helpers/analytics/userInterestAnalytics';;
import getCookie from '../utils/getCookie';

const client = insightsClient(ENV.algoliaAppId, ENV.algoliaApiKey);

import { User } from '../types/api/UsersTypes';
import { CategoryResponse } from '../types/api/CategoriesIndexTypes';
import { CollectionResponse } from '../types/api/CollectionsIndexTypes';

const getTimestampAndFingerprint = (): { timestamp: number, browser_fingerprint: string } => {
  touchCookie();
  const timestamp = Date.now();
  const browser_fingerprint = getCookie(BROWSER_FINGERPRINT_COOKIE_NAME);
  return { timestamp, browser_fingerprint };
};

const pushAddToCartEvent = async (collectionIds: Array<number>, user: User): Promise<void> => {
  const { timestamp, browser_fingerprint } = getTimestampAndFingerprint();
  try {
    await client.pushEvents({
      events: [
        {
          eventType: 'conversion',
          eventSubtype: 'addToCart',
          eventName: EVENT_NAMES.ADD_TO_CART,
          index: ENV.algoliaCollectionsIndex,
          userToken: browser_fingerprint,
          authenticatedUserToken: user?.id?.toString(),
          timestamp,
          objectIDs: collectionIds.map(id => id.toString()),
        },
      ],
    });
  } catch {};
};

const pushAddToWishlistEvent = async (collectionIds: Array<number>, user: User): Promise<void> => {
  const { timestamp, browser_fingerprint } = getTimestampAndFingerprint();
  try {
    await client.pushEvents({
      events: [
        {
          eventType: 'conversion',
          eventName: EVENT_NAMES.ADD_TO_WISHLIST,
          index: ENV.algoliaCollectionsIndex,
          userToken: browser_fingerprint,
          authenticatedUserToken: user?.id?.toString(),
          timestamp,
          objectIDs: collectionIds.map(id => id.toString()),
        },
      ],
    });
  } catch {};
};

const pushCategoryViewEvent = async (user: User, category: CategoryResponse) => {
  const { timestamp, browser_fingerprint } = getTimestampAndFingerprint();
  try {
    await client.pushEvents({
      events: [
        {
          eventType: 'click',
          eventName: EVENT_NAMES.CLICK_CATEGORY,
          index: ENV.algoliaCollectionsIndex,
          userToken: browser_fingerprint,
          authenticatedUserToken: user?.id?.toString(),
          timestamp,
          filters: [`${ATTRIBUTES.CATEGORIES}:${category.name}`],
        },
      ],
    });
  } catch {};
};

const pushProductViewEvent = async (user: User, product: CollectionResponse) => {
  const { timestamp, browser_fingerprint } = getTimestampAndFingerprint();
  try {
    const events: Array<EventsItems> = [
      {
        eventType: 'click',
        eventName: EVENT_NAMES.CLICK_PRODUCT,
        index: ENV.algoliaCollectionsIndex,
        userToken: browser_fingerprint,
        authenticatedUserToken: user?.id?.toString(),
        timestamp,
        objectIDs: [product.id.toString()],
      },
    ];
    if (product.category)
      events.push({
        eventType: 'view',
        eventName: EVENT_NAMES.VIEW_CATEGORY,
        index: ENV.algoliaCollectionsIndex,
        userToken: browser_fingerprint,
        authenticatedUserToken: user?.id?.toString(),
        timestamp,
        filters: [`${ATTRIBUTES.CATEGORIES}:${product.category.name}`],
      });
    await client.pushEvents({ events });
  } catch {};
};

const algoliaHelpers = {
  pushAddToCartEvent,
  pushCategoryViewEvent,
  pushProductViewEvent,
  pushAddToWishlistEvent,
};

export default algoliaHelpers;
