import { BundleDiscountUiStateProps, BundleDiscountUiProps } from '../../types/components/checkout/BundleDiscountUi';
import { ApplicationState } from '../../types/state/storeTypes';

import { connect } from 'react-redux';

import { CART_CONSTANTS } from '../../constants/ui';

// TODO test
const BundleDiscountUi: React.FC<BundleDiscountUiProps> = ({
  cart,
}) => {
  const shouldDisplay = (): boolean => {
    return !!Object.values(
      cart.order.products
    ).find(
      product => product.name === CART_CONSTANTS.BUNDLE_DISCOUNT_PRODUCT_NAME
    );
  };

  const getSubtotal = (): number => {
    const prices = Object.values(cart.order.products).filter(
      product => parseFloat(product.price) >= 0
    ).map(product => product.price);
    let ret = 0;
    prices.forEach(p => ret += parseFloat(p));
    return ret;
  };

  const getDiscountPrice = (): number => {
    const product = Object.values(cart.order.products).find(
      product => product.name === CART_CONSTANTS.BUNDLE_DISCOUNT_PRODUCT_NAME
    );
    // the price comes as a negative number frome the BE,
    // but we need to add the dollar sign, so multipy by -1
    // and return as a positive number
    return parseFloat(product.price) * -1;
  };

  if (!shouldDisplay()) return;

  return (
    <div
      cy-test-id="bundle-discount-ui"
      className="flex flex-col gap-2.5 w-full"
    >
      <div className="flex text-white font-16 leading-24 inter">
        <span>
          Subtotal:
        </span>
        <span className="ml-auto">
          ${getSubtotal().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </span>
      </div>
      <div className="flex text-white font-16 leading-24 inter">
        <span>
          Bundle discount:
        </span>
        <div className="ml-auto flex gap-2.5">
          <span className="text-[#00B74F]">
            -{CART_CONSTANTS.BUNDLE_DISCOUNT_PERCENT}%
          </span>
          <span>
            -${getDiscountPrice().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): BundleDiscountUiStateProps => ({
  cart: state.cart,
});

export default connect(mapStateToProps)(BundleDiscountUi);
