import {
  AppliedDiscountCodeUiProps,
  AppliedDiscountCodeUiStateProps,
} from '../../types/components/checkout/AppliedDiscountCodeUi';
import { ApplicationState } from '../../types/state/storeTypes';

import { connect } from 'react-redux';

const AppliedDiscountCodeUi: React.FC<AppliedDiscountCodeUiProps> = ({
  cart,
}) => {
  if (!cart.order.discount?.code) return <></>;

  return (
    <div className="flex text-white font-16 leading-24 inter items-center" cy-test-id="applied-discount-code-ui">
      <span className="flex flex-col">
        <span>Discount code:</span>
        <span cy-test-id="discount-code">{cart.order.discount.code}</span>
      </span>
      <span className="ml-auto text-[#00B74F]" cy-test-id="discount-price">
        -${parseFloat(cart.order.discount.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2})}
      </span>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): AppliedDiscountCodeUiStateProps => ({
  cart: state.cart,
});

export default connect(mapStateToProps)(AppliedDiscountCodeUi);
