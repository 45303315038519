import { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useRouter } from 'next/router';

import LicenseConfirmation from './LicenseConfirmation';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import { Loader } from '../shared/Loader';
import MultiplePaypalFlowsModal from './MultiplePaypalFlowsModal';
import SelectedPlanVariantDetails from './SelectedPlanVariantDetails';
import StripeCreditBalanceBlock from '../payment/StripeCreditBalanceBlock';
import DiscountBlock from './DiscountBlock';
import LicenseAgreementAndErrorBlock from './LicenseAgreementAndErrorBlock';

import { OrderSummaryProps, OrderSummaryStateProps } from '../../types/components/checkout/OrderSummary';
import { ApplicationState } from '../../types/state/storeTypes';
import { User } from '../../types/api/UsersTypes';
import { StripeErrorResponse } from '../../types/api/stripe/error';
import { AxiosError } from 'axios';
import {
  InitializePaypalSubscriptionPayload,
  InitializePaypalPaymentResponse,
} from '../../types/helpers/paypalHelpers';
import { GenericResponse } from '../../types/api/Http';
import { CreditPackResponse } from '../../types/api/CreditsTypes';
import { CheckoutResponse } from '../../types/helpers/checkoutHelpers';
import { ConfirmSubscriptionOrderResponse, SubscriptionDetail, PlanVariantResponse } from '../../types/api/SubscriptionTypes';
import { StripeCustomerData } from '../../types/api/stripe/customer';

import paymentHelpers from '../../helpers/paymentHelpers';
import subscribeHelpers from '../../helpers/pages/checkout/subscribe';
import paypalHelpers from '../../helpers/paypalHelpers';
import navigationHelpers from '../../helpers/navigationHelpers';
import checkoutHelpers from '../../helpers/checkoutHelpers';
import creditsHelpers from '../../helpers/creditsHelpers';

export const licenseConfirmationHighlightClass = 'border border-1 border-orange-900 rounded-[5px] shadow-lg p-3 mb-4 mt-4';
export const licenseConfirmationDefaultClass = 'py-3 my-2';

import { ENV } from '../../constants/environments';
import { AUTH_ACTIONS } from '../../constants/actions';
const { AUTH_ON_SUBSCRIBED } = AUTH_ACTIONS;
import { setAvailableCredits } from '../../state/actions/creditActions';
import { setPurchasedCreditPack, clearPurchasedCreditPack } from '../../state/actions/eventTrackingActions';
import DiscountCode from './DiscountCode';

const OrderSummary = (props: OrderSummaryProps) => {
  const {
    selectedLicenseId,
    selectedPlanVariant,
    selectedCreditPack,
    onRemoveAddOn,
    selectedPaymentMethod,
    authenticated,
    submitDisabled,
    user,
    onFlowCompleted,
    customSubmitButtonText,
    customSubmitButtonClass,
    customRenewalTooltip,
    doNotExecuteAfterSubscriptionLogic,
    currentPlan,
    selectedCardId,
    // TODO test
    isSubscriptionDowngrade,
    licenseConfirmNotRequired,
    renderCreditWarning,
    remainingLegacyCredits
  } = props;
  const { subscribe } = subscribeHelpers;
  const {
    initializeSubscription,
    purchaseCreditPack,
    purchaseCustomCreditsAmount,
  } = paypalHelpers;
  const { navigateAway } = navigationHelpers;
  const { checkoutUsingNewCard, checkoutUsingSavedCard, getRenewalPrice } = checkoutHelpers;
  const { calculateCustomAmountPrice } = creditsHelpers; // TODO test

  const elements = useElements();
  const stripe = useStripe();
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearPurchasedCreditPack());
    }
  }, []);

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [licenseHighlight, setLicenseHighlight] = useState(false);
  const [licenseConfirmed, setLicenseConfirmed] = useState(licenseConfirmNotRequired ? true : false);
  const [showPaypalFlowsModal, setShowPaypalFlowsModal] = useState(false);
  const [subscriptionPaypalRedirectUrl, setSubscriptionPaypalRedirectUrl] = useState<string>();
  const [addOnPaypalRedirectUrl, setAddOnPaypalRedirectUrl] = useState<string>();
  const [discount, setDiscount] = useState<number>(0); // Stripe Credits
  const [coupon, setCoupon] = useState(null); // Coupon Codes

  const displayPromoCodeInput = (): boolean => {
    return getTotalPrice() > 0 && ENV.displaySaleBanner !== 'true';
  }

  const onStripeCreditBalanceLoaded = (data: StripeCustomerData) => {
    if (data.balance >= 0) return;
    const balance = data.balance / 100 * -1;
    setDiscount(balance);
  };

  const getPaymentButtonText = (): string => {
    if (customSubmitButtonText) return customSubmitButtonText;
    return paymentHelpers.getPaymentButtonText(selectedPaymentMethod, getTotalPrice());
  }

  const getPlanPrice = (): number => {
    return parseFloat(selectedPlanVariant?.adjusted_price || selectedPlanVariant?.price || '0');
  }

  const getAddonTitle = (): string => {
    if (!selectedCreditPack) return '';
    const {
      data: selectedCreditPackData,
      isCustomCreditPack,
      customCreditPackAmount,
    } = selectedCreditPack;
    const amount = isCustomCreditPack
      ? customCreditPackAmount
      : (selectedCreditPackData as CreditPackResponse).recurring_credits;
    return `${amount || 0} Credits`;
  }

  const getAddOnPrice = (): number => {
    if (!selectedCreditPack) return 0;
    const creditPackData = selectedCreditPack.data as CreditPackResponse;
    const { isCustomCreditPack, customCreditPackAmount, priceRange } = selectedCreditPack;
    const price = isCustomCreditPack
      ? calculateCustomAmountPrice(customCreditPackAmount, priceRange)
      : parseFloat(creditPackData.adjusted_price || creditPackData.price);
    return price;
  }

  const getCouponDiscount = (): number => {
    const couponMultiplier = parseFloat(coupon.percent_off) / 100;
    return getPlanPrice() * couponMultiplier;
  }

  const getTotalPrice = (): number => {
    if (isSubscriptionDowngrade) return 0;

    const couponMultiplier = coupon?.percent_off && !selectedPlanVariant.adjusted_price ? 1 - (parseFloat(coupon.percent_off) / 100) : 1;
    let subscriptionPrice = (getPlanPrice() * couponMultiplier) - discount;
    if (subscriptionPrice < 0) subscriptionPrice = 0;
    const addonPrice = getAddOnPrice();
    return subscriptionPrice + addonPrice;
  }

  const errorHandler = (result: AxiosError | StripeErrorResponse) => {
    setLoading(false);
    setError((result as AxiosError).message || (result as StripeErrorResponse).error.message);
  }

  const onSubmit = () => {
    if (loading) return;
    setError('');
    if (!licenseConfirmed) {
      setLicenseHighlight(true);
      return;
    }

    if (coupon?.percent_off && selectedPaymentMethod == 'paypal') {
      setError('Unfortunately, we do not support promo codes with PayPal checkout. Please remove the promo code or use normal checkout to continue');
      return;
    }

    switch (selectedPaymentMethod) {
      case 'card':
        return saveCard();
      case 'paypal':
        return payByPayPal();
      case 'saved_card':
        return useSavedCard();
      default:
        return;
    }
  }

  const payByPayPal = async () => {
    setLoading(true);
    const subscriptionPurchaseResult = await purchaseSubscriptionByPayPal();
    if (!subscriptionPurchaseResult) return;
    if (!selectedCreditPack) return navigateAway(subscriptionPurchaseResult);
    const addonPurchaseResult = await purchaseAddOnByPayPal();
    if (!addonPurchaseResult) return;
    setSubscriptionPaypalRedirectUrl(subscriptionPurchaseResult);
    setAddOnPaypalRedirectUrl(addonPurchaseResult);
    setLoading(false);
    setShowPaypalFlowsModal(true);
  }

  const purchaseSubscriptionByPayPal = async (): Promise<string | undefined> => {
    const payload: InitializePaypalSubscriptionPayload = {
      plan_variant_id: selectedPlanVariant.id.toString(),
      license_id: selectedLicenseId.toString(),
    };
    const result = await initializeSubscription(payload, user);
    if ((result as AxiosError).status !== 200) {
      errorHandler(result as AxiosError);
      return;
    }
    const redirectUrl = (result as GenericResponse<InitializePaypalPaymentResponse>).data.redirect_url;
    return redirectUrl;
  }

  const purchaseAddOnByPayPal = async (): Promise<string | undefined> => {
    const { data, isCustomCreditPack, customCreditPackAmount } = selectedCreditPack;
    const result = isCustomCreditPack ?
      await purchaseCustomCreditsAmount(customCreditPackAmount.toString(), selectedPlanVariant.id.toString(), user) :
      await purchaseCreditPack(data as CreditPackResponse, user);
    if ((result as AxiosError).status !== 200) {
      errorHandler(result as AxiosError);
      return;
    }
    const redirectUrl = (result as GenericResponse<InitializePaypalPaymentResponse>).data.redirect_url;
    return redirectUrl;
  }

  const getAddOnPriceConfig = (): { amount: number } | undefined => {
    if (!selectedCreditPack) return;
    const { isCustomCreditPack, customCreditPackAmount, data, priceRange } = selectedCreditPack;
    const pack = data as CreditPackResponse;
    const addOnPrice = isCustomCreditPack
      ? calculateCustomAmountPrice(customCreditPackAmount, priceRange)
      : parseFloat(pack.adjusted_price || pack.price);
    return { amount: addOnPrice };
  }

  const saveCard = async () => {
    const submitElementsResult = await elements.submit();
    if (submitElementsResult.error) {
      return;
    }
    setLoading(true);
    const addOnPriceConfig = getAddOnPriceConfig();
    const result = await checkoutUsingNewCard(user, elements, stripe, addOnPriceConfig);
    (result as StripeErrorResponse)?.error
      ? errorHandler(result as AxiosError | StripeErrorResponse)
      : handleSubscribe(result as CheckoutResponse);
  }

  const useSavedCard = async () => {
    setLoading(true);
    const addOnPriceConfig = getAddOnPriceConfig();
    const result = await checkoutUsingSavedCard(user, stripe, selectedCardId, addOnPriceConfig);
    (result as StripeErrorResponse)?.error
      ? errorHandler(result as AxiosError | StripeErrorResponse)
      : handleSubscribe(result as CheckoutResponse);
  }

  const handleSubscribe = async (checkoutResult: CheckoutResponse) => {
    const {
      confirmSetupIntentData,
      confirmPaymentIntentData,
    } = checkoutResult;
    const result = await subscribe(
      user,
      selectedPlanVariant,
      confirmSetupIntentData.setupIntent.id,
      selectedLicenseId,
      dispatch,
      currentPlan,
      selectedCreditPack,
      confirmPaymentIntentData?.id,
      coupon?.code
    );
    const subscription = (result as ConfirmSubscriptionOrderResponse).subscription;
    subscription
      ? afterSubscription(subscription)
      : errorHandler(result as AxiosError);
  }

  const afterSubscription = (subscription: SubscriptionDetail) => {
    if (selectedCreditPack) {
      const { customCreditPackAmount, data } = selectedCreditPack;
      const pack = data as CreditPackResponse;
      dispatch(setPurchasedCreditPack(pack, customCreditPackAmount));
    };
    if (onFlowCompleted) onFlowCompleted();
    if (doNotExecuteAfterSubscriptionLogic) return;
    const currentCreditBalance = currentPlan?.current_credit_balance || 0;
    dispatch({ type: AUTH_ON_SUBSCRIBED, subscription });
    let incrementValue = selectedPlanVariant.recurring_credits;
    if (selectedCreditPack) {
      const { isCustomCreditPack, customCreditPackAmount, data } = selectedCreditPack;
      const amount = isCustomCreditPack ? customCreditPackAmount : data.recurring_credits;
      incrementValue += amount;
    }
    dispatch(setAvailableCredits(currentCreditBalance + incrementValue));
    router.push('/thank-you');
  }

  const renderOrderItemWrapper = (children: React.ReactElement, testId: string, className?: string): React.ReactElement => {
    return (
      <div className={`w-full rounded-[5px] p-4 bg-gray-800 ${className || ''}`} cy-test-id={testId}>
        {children}
      </div>
    );
  }

  const renderSelectedPlanVariantDetails = (): React.ReactElement => {
    return <SelectedPlanVariantDetails
      selectedPlanVariant={selectedPlanVariant}
      customRenewalTooltip={customRenewalTooltip}
      renewalPrice={getRenewalPrice(selectedPlanVariant as PlanVariantResponse, coupon)}
    />
  }

  const renderAdditionalPurchaseDetails = (): React.ReactElement => {
    if (!selectedCreditPack) return;
    const title = getAddonTitle();
    const price = getAddOnPrice();
    return (
      <div className="w-full" cy-test-id="additional-purchase-details">
        <div className="w-full flex text-white">
          <span className="inter text-18 leading-28 font-semibold" cy-test-id="additional-purchase-title">
            {title}
          </span>
          <span className="inter ml-auto text-18 leading-28 font-semibold" cy-test-id="additional-purchase-price">
            ${price.toFixed(2)}
          </span>
        </div>
        {
          !!onRemoveAddOn &&
          <div className="w-full flex" cy-test-id="remove-add-on">
            <span
              className="text-a-gray inter ml-auto text-right text-14 leading-22 font-normal hover:underline cursor-pointer"
              onClick={() => onRemoveAddOn(selectedCreditPack)}
            >
              Remove add-on
            </span>
          </div>
        }
      </div>
    );
  }

  const renderOrderItemsBlock = (): React.ReactElement => {
    const selectedPlanVariantView = renderSelectedPlanVariantDetails();
    const additionalPurchaseView = renderAdditionalPurchaseDetails();
    return (
      <div className="w-full mt-[32px]" cy-test-id="order-items-block">
        {renderOrderItemWrapper(selectedPlanVariantView, 'selected-plan-variant-details-wrapper')}
        {
          selectedCreditPack &&
          <>
            {renderOrderItemWrapper(additionalPurchaseView, 'additional-purchase-details-wrapper', 'mt-4')}
          </>
        }
      </div>
    );
  }

  const renderTotalPriceBlock = (): React.ReactElement => {
    return (
      <div className="mt-6">
        {getTotalPrice() > 0 && coupon && coupon.percent_off &&
          <div className="flex items-center" cy-test-id="subtotal-price-block">
            <div className="text-[16px] leading-24 inter text-white">Subtotal:</div>
            <div className="ml-auto font-400 text-[16px] text-white">
              ${getPlanPrice().toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
            </div>
          </div>
        }

        {getTotalPrice() > 0 && coupon && coupon.percent_off &&
          <div className="flex items-center mt-3" cy-test-id="discount-price-block">
            <div className="text-[16px] leading-24 inter text-white flex-grow">
              Discount:
            </div>
            <span className="text-a-green mr-2">-{coupon.percent_off}%</span>
            <div className="ml-auto font-400 text-[16px] text-white">
              -${getCouponDiscount().toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
            </div>
          </div>
        }

        { getTotalPrice() > 0 && coupon?.duration_in_months && coupon?.duration_in_months > 0 &&
          <div className="text-gray-400 text-[13px] mt-1 mb-3 leading-snug">
            { selectedPlanVariant.interval == 'month' &&
              <>Applies to first {coupon.duration_in_months} months.</>
            }
            { selectedPlanVariant.interval == 'year' && coupon.duration_in_months <= 12 &&
              <>Plan will renew at full-price after the first year.</>
            }
          </div>
        }


        <DiscountBlock
          selectedPlanVariant={selectedPlanVariant}
          selectedCreditPack={selectedCreditPack}
        />
        <StripeCreditBalanceBlock
          className="w-full rounded-[5px] p-4 bg-gray-800 text-white inter text-18 leading-28 font-semibold my-6"
          onDataLoaded={onStripeCreditBalanceLoaded}
        />

        <div className="flex items-center" cy-test-id="total-price-block">
          <div className="text-[18px] leading-24 inter font-medium text-white">Total:</div>
          <div className="ml-auto font-bold text-[28px] text-white">
            ${getTotalPrice().toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
          </div>
        </div>
      </div>
    );
  }

  const renderLicenseConfirmationBlock = (): React.ReactElement => {
    if (licenseConfirmNotRequired) return <></>;
    return (
      <div
        cy-test-id="license-confirmation-block"
        className={`text-white ${licenseHighlight && !licenseConfirmed
          ? licenseConfirmationHighlightClass
          : licenseConfirmationDefaultClass

          }`}
      >
        <LicenseConfirmation
          selectedLicenseId={selectedLicenseId}
          setLicenseConfirmed={setLicenseConfirmed}
        />
      </div>
    );
  }

  const renderPaymentMethodBlock = (): React.ReactElement => {
    switch (authenticated) {
      case true:
        return (
          <div className="mb-6 mt-3" cy-test-id="payment-method-block">
            {selectedPaymentMethod && (
              <MainLinkButton
                onClick={onSubmit}
                className={`w-full !h-[56px] py-4 tracking-wider ${customSubmitButtonClass}`}
                variant="green"
                disabled={loading || submitDisabled}
                testId="active-place-order-button"
              >
                {!loading ? getPaymentButtonText() : 'Loading...'}
              </MainLinkButton>
            )}
            {!selectedPaymentMethod && (
              <button
                className="bg-gray-600 text-white w-full pt-3 py-4 font-bold text-[15px] leading-tight eurostile uppercase rounded-[5px] tracking-wider"
                cy-test-id="disabled-place-order-button"
              >
                Enter your payment method to continue
              </button>
            )}
          </div>
        );
      case false:
        return (
          <div className="mb-8" cy-test-id="payment-method-unauthenticated-block">
            <button className="bg-gray-600 text-white w-full pt-3 py-4 font-bold text-[15px] leading-tight eurostile uppercase rounded-[5px] tracking-wider">
              Enter your email to continue
            </button>
          </div>
        )
      case undefined:
      default:
        return <></>;

    }
  }

  const creditWarning = (): React.ReactElement => {
    return (
      <div cy-test-id="legacy-warning" className='inline flex-col bg-[#FF9D0033] w-full rounded-[5px] mt-10 text-white font-light text-[16px] p-4 border border-[#FFAC26]'>
        <span className='text-[#FF6870] font-bold'>Warning:</span>
        <span>You are currently subscribed to a legacy credit plan. When you <span className='font-bold'>upgrade</span> or <span className='font-bold'>cancel</span> this plan you will lose your <span className='font-bold'>{remainingLegacyCredits}</span> legacy credits. We recommend using your credits before making any changes to your subscription.</span>
      </div>
    )
  }

  return (
    <>
      <fieldset cy-test-id="order-summary">
        <legend className="font-bold leading-24 inter text-[21px] text-white" cy-test-id="title">Order Summary</legend>
        {renderCreditWarning && creditWarning()}
        {renderOrderItemsBlock()}

        {displayPromoCodeInput() && <DiscountCode coupon={coupon} setCoupon={setCoupon} />}

        {renderTotalPriceBlock()}
        {renderLicenseConfirmationBlock()}
        {renderPaymentMethodBlock()}
        <LicenseAgreementAndErrorBlock
          paymentButtonText={getPaymentButtonText()}
          error={error}
        />
      </fieldset>
      <MultiplePaypalFlowsModal
        subscriptionRedirectUrl={subscriptionPaypalRedirectUrl}
        addOnRedirectUrl={addOnPaypalRedirectUrl}
        isOpen={showPaypalFlowsModal}
        setIsOpen={setShowPaypalFlowsModal}
      />
      {loading && <Loader />}
    </>
  );
}

const mapStateToProps = (state: ApplicationState): OrderSummaryStateProps => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user as User,
  currentPlan: state.auth.subscription as SubscriptionDetail,
});

export default connect(mapStateToProps)(OrderSummary);
