import { User } from '../types/api/UsersTypes';
import { SubscriptionDetail } from '../types/api/SubscriptionTypes';
import { CollectionsShowResponse } from '../types/api/CollectionsShowTypes';
import { CollectionResponse } from '../types/api/CollectionsIndexTypes';
import { ProductResponse } from '../types/api/ProductsIndexTypes';
import { NextRouter } from 'next/router';
import { Dispatch } from 'redux'
import { CartState } from '../types/state/reducers/cartReducersTypes';
import { ProductVariantPrice } from '../types/state/reducers/productVariantsPricesTypes';
import { GenericResponse } from '../types/api/Http';
import axios, { AxiosResponse } from 'axios';

import { ENV } from '../constants/environments';

const apiUrl = ENV.api.baseURL;

import { addProductThunk } from '../state/actions/cartActions';
import subscriptionUpgradeHelpers from './subscriptionUpgradeHelpers';

export const ADD_TO_CART_TEXT = 'Add to Cart';
export const PURCHASE_TEXT = 'Buy Now';

const getButtonText = (user: User, authenticated: boolean): string => {
  return authenticated && user?.team?.licensee_profile?.license_id ? ADD_TO_CART_TEXT : PURCHASE_TEXT;
}

const shouldHideAddToCart = (collection: CollectionsShowResponse | ProductResponse | CollectionResponse, subscription?: SubscriptionDetail): boolean => {
  return collection.free_product || (collection.membership_tier === 'silver' && !!subscription);
}

// TODO test
const determineNonSubscriptionPurchaseAction = (
  user?: User,
  subscription?: SubscriptionDetail,
  cart?: CartState,
): 'instant-purchase' | 'add-to-cart' => {
  if (Object.keys(cart?.order?.products || {}).length > 0) return 'add-to-cart';
  const isLoggedIn = !!user?.id;
  const isSubscribed = !!subscription?.id;
  const licenseKnown = !!user?.min_license_id || !!user?.team?.licensee_profile?.license_id || !!subscription?.license?.id;

  if (
    isLoggedIn && isSubscribed ||
    isLoggedIn && !isSubscribed && licenseKnown
  ) return 'add-to-cart';

  if (
    !isLoggedIn ||
    isLoggedIn && !isSubscribed && !licenseKnown
  ) return 'instant-purchase';
}

// TODO test
const handleNonSubscriptionButtonClick = (
  router: NextRouter,
  dispatch: Dispatch,
  collection: CollectionResponse,
  cart: CartState,
  user?: User,
  subscription?: SubscriptionDetail,
): void => {
  const { determineNonSubscriptionPurchaseAction } = nonSubscriptionPurchaseHelpers;

  const redirectToInstantPurchase = () => {
    router.push({
      pathname: '/instant-purchase',
      query: { 'collection-slug': collection.slug },
    });
  };

  const addToCart = () => {
    const { getMinimalLicenseIdForUpgrade } = subscriptionUpgradeHelpers;
    const licenseId = getMinimalLicenseIdForUpgrade(user, subscription, 'license_id');
    const selectedVariant = collection.collection_variants.sort(
      (v1, v2) => v2.price - v1.price
    )[0];
    dispatch(addProductThunk(cart.order, selectedVariant.id, licenseId, user));
  };

  const action = determineNonSubscriptionPurchaseAction(user, subscription, cart);

  switch (action) {
    case 'instant-purchase': return redirectToInstantPurchase();
    case 'add-to-cart': return addToCart();
    default: return;
  };
}

// TODO test
const cartUiVisible = (router: NextRouter): boolean => {
  return !router.asPath.includes('checkout') && !router.asPath.includes('instant-purchase');
}

// TODO test
const fetchCollectionPriceData = async (collection: CollectionResponse, user?: User): Promise<ProductVariantPrice[]> => {
  const url = `${apiUrl}/products/${collection.id}/prices`;
  const result: AxiosResponse<GenericResponse<ProductVariantPrice[]>> = await axios.get(url, { headers: { authorization: user?.token }});
  return result.data.data;
};

  const determineSavingsInCash = (licenseId, cashPrice, creditPrice) => {
    switch (licenseId) {
      case 1:
        return cashPrice - creditPrice * 13.8;
      case 2:
        return cashPrice - creditPrice * 45.8;
      case 3:
        return cashPrice - creditPrice * 91.6;
      case 4:
        return cashPrice - creditPrice * 137.4;
      case 5:
        return cashPrice - creditPrice * 183.2;
      case 6:
        return cashPrice - creditPrice * 229;
      default:
        return;
    }
  };

const nonSubscriptionPurchaseHelpers = {
  getButtonText,
  shouldHideAddToCart,
  handleNonSubscriptionButtonClick,
  determineNonSubscriptionPurchaseAction,
  cartUiVisible,
  fetchCollectionPriceData,
  determineSavingsInCash
};

export default nonSubscriptionPurchaseHelpers;
