import { EssentialsMonthlyMarkerProps } from '../../types/components/shared/EssentialsMonthlyMarker';

export const sizeClasses = {
  'lg': 'h-10 leading-[13px] text-14',
  'sm': 'h-5 md:h-[30px] leading-[12px] text-[10px] md:leading-[13px] md:text-14',
};

const EssentialsMonthlyMarker: React.FC<EssentialsMonthlyMarkerProps> = ({
  essentialsMonthlyExpiryDate,
  size,
}) => {
  if (!essentialsMonthlyExpiryDate) return;

  const formattedDate = (): string => {
    const date = new Date(essentialsMonthlyExpiryDate);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  return (
    <div
      cy-test-id="essentials-monthly-marker"
      className={`${sizeClasses[size]} px-2 py-[3px] bg-[#1f3d48]/70 rounded-tr-[3px] rounded-br-[3px] justify-center items-center gap-1.5 inline-flex uppercase inter font-bold text-blue-800`}
    >
      until {formattedDate()}
    </div>
  );
}

export default EssentialsMonthlyMarker;
