import { CartProductProps } from '../../../types/layout/ShoppingCartTypes';
import { TrashIcon } from '../../../modules/Icons';
import { ENV } from '../../../constants/environments';

const CartProduct: React.FC<any> = (props) => {
  const { product, rmItem } = props;
  const { id, default_thumbnail, name, price, resolution } = product;

  return (
    <div className="flex items-center gap-4 py-4 text-white" data-testid="cartProduct">
      <img
        className="w-16 rounded-[5px]"
        src={ENV.uploadsUrl + default_thumbnail || '/img/default-cart-item.jpg'}
        alt={name}
        data-testid="productImg"
      />
      <div className="">
        <p className="font-medium text-[16px]" data-testid="productName">
          {name}
        </p>
      </div>
      <p className="ml-auto text-right" data-testid="productPrice">
        ${parseFloat(price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </p>
      <div className="p-2 cursor-pointer" onClick={() => rmItem(id)} data-testid="productRemoveBtn">
        <TrashIcon className="trash" />
      </div>
    </div>
  );
};

export default CartProduct;
