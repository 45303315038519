// TODO test
import { LicenseAgreementAndErrorBlockProps } from '../../types/components/checkout/LicenseAgreementAndErrorBlock';

import { PayPalIcon, StripeIcon } from '../../modules/Icons';
import Link from 'next/link';

const LicenseAgreementAndErrorBlock: React.FC<LicenseAgreementAndErrorBlockProps> = ({
  error,
  paymentButtonText,
}) => {
  const renderError = (): React.ReactElement => {
    return error && <p className="bg-red-900 text-white p-3 text-[12px]" cy-test-id="error-block">{error}</p>
  };

  return (
    <div className="flex flex-col gap-8" cy-test-id="license-agreement-block">
      <p className="text-14 px-5 leading-[20px] text-center text-white">
        By clicking "{paymentButtonText}" you agree to our
        <Link className="text-a-blue" href="/terms-of-service">
          {' '}
          Terms of Service
        </Link>{' '}
        and
        <Link className="text-a-blue" href="/licenses">
          {' '}
          License Agreement
        </Link>
        .
      </p>

      {renderError()}
      <div className="flex items-center justify-center gap-4">
        <StripeIcon />
        <PayPalIcon />
      </div>
    </div>
  );
}

export default LicenseAgreementAndErrorBlock;
