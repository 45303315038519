import { CART_CONSTANTS } from '../constants/ui';

import { CartProductType } from '../types/state/reducers/cartReducersTypes';

// TODO test
const filterOutDiscountItem = (product: CartProductType): boolean => {
  return product.name !== CART_CONSTANTS.BUNDLE_DISCOUNT_PRODUCT_NAME;
};

const cartHelpers = {
  filterOutDiscountItem,
};

export default cartHelpers;
