import { updateOrderAction, setLoading } from '../../state/actions/cartActions';
import store from '../../state/store';
import { ENV } from '../../constants/environments';
import { OrderResponse, OrderCreateRequest } from '../../types/api/OrderTypes';

export class OrderClient {
  private apiUrl: string = ENV.api.baseURL;
  private ordersUrl: string = `${this.apiUrl}/orders`;

  getOrder(): void {
    const orderToken = localStorage.getItem('avfx_order');
    let tokenQuery = '';
    if (orderToken?.length > 0 && orderToken != 'null') {
      tokenQuery = '?token=' + orderToken;
    }

    store.dispatch({
      type: 'GET_CURRENT_ORDER',
      api: {
        onSuccess: (res) => this.updateOrder(res.data),
        onError: (res) => console.log(res),
        url: `${this.ordersUrl}/shopping_cart${tokenQuery}`,
        method: 'GET',
        headers: {
          authorization: store.getState()?.auth?.user?.token
        }
      }
    });
  }

  createOrder(order: OrderCreateRequest, cb: () => void = null): void {
    const orderToken = localStorage.getItem('avfx_order');
    let tokenObj = {};
    if (orderToken?.length > 0 && orderToken != 'null') {
      tokenObj = { token: orderToken };
    }
    store.dispatch(setLoading());
    store.dispatch({
      type: 'CREATE_ORDER',
      api: {
        onSuccess: (res) => {
          const order: OrderResponse = res.data;
          this.updateOrder(order, false, cb);
        },
        url: `${this.ordersUrl}/shopping_cart`,
        method: 'PUT',
        headers: {
          authorization: store.getState()?.auth?.user?.token
        },
        data: {
          ...tokenObj,
          product_ids: order.products,
          license: order.licenseId
        }
      }
    });
  }

  updateOrder(orderResponse: any, discountError: boolean = false, cb: () => void = null): void {
    orderResponse.total = +orderResponse.total;
    orderResponse.subtotal = +orderResponse.subtotal;

    const order: OrderResponse = orderResponse;
    localStorage.setItem('avfx_order', order.token);

    const { license_id, discount, items, subtotal, total, token } = order;
    store.dispatch(
      updateOrderAction(
        Object.assign({}, {
          total: total,
          subtotal: subtotal,
          token: token,
          licenseId: license_id,
          products: items,
          discount: {
            ...discount,
            error: discountError
          }
        } as any)
      )
    );
    if (cb) cb();
  }

  addItem(order: any, callback?: () => void): void {
    const orderToken = localStorage.getItem('avfx_order');
    let tokenObj = {};
    if (orderToken?.length > 0 && orderToken != 'null') {
      tokenObj = { token: orderToken };
    }

    store.dispatch({
      type: 'ADD_ITEM',
      api: {
        // onSuccess: (res) => this.applyDiscount(res.data),
        headers: {
          'Content-Type': 'application/json',
          authorization: store.getState()?.auth?.user?.token
        },
        url: `${this.ordersUrl}/shopping_cart`,
        method: 'PUT',
        data: JSON.stringify({
          ...order,
          ...tokenObj
        }),
        onSuccess: (res) => {
          this.updateOrder(res.data, false, callback);
        }
      }
    });
  }

  removeItem(order: OrderCreateRequest): void {
    this.createOrder(order);
  }

  changeLicense(order: OrderCreateRequest): void {
    this.createOrder(order);
  }

  addDiscount(order: OrderCreateRequest, successCb?: Function, errorCb?: Function): void {
    const isAddingDiscount = !!order.discount;
    const requestConfig: any = {
      onSuccess: (res) => {
        this.applyDiscount(res.data);
        if (successCb)
          successCb(res.data);
      },
      onError: (err) => {
        if (errorCb)
          errorCb(err)
      },
      url: `/orders/discount`,
      method: isAddingDiscount ? 'PUT' : 'DELETE',
      headers: {
        authorization: store.getState()?.auth?.user?.token
      },
    };
    if (isAddingDiscount)
      requestConfig.data = { discount_code: order.discount }

    store.dispatch({
      type: 'APPLY_DISCOUNT',
      api: requestConfig,
    });
  }

  applyDiscount(res: OrderResponse): void {
    if (res.discount.value) {
      this.updateOrder(res);
    } else {
      this.updateOrder(res, true);
    }
  }
}
