import { ANALYTICS_ACTIONS } from '../../constants/actions';
const { ADD_TO_CART, INIT_CHECKOUT, CONFIRMED_PAYMENT_INFO, PURCHASE_COMPLETE } = ANALYTICS_ACTIONS;
import { OrderResponse } from './../../types/api/OrderTypes';

export const addToCartEvent = (id: number) => ({ type: ADD_TO_CART, id });

export const initCheckoutEvent = () => ({ type: INIT_CHECKOUT });

export const addPaymentInfoEvent = () => ({ type: CONFIRMED_PAYMENT_INFO });

export const purchasedEvent = (order: OrderResponse) => {
  return { type: PURCHASE_COMPLETE, order };
};
